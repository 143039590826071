import React from "react";
import { Link } from "react-router-dom";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.sliderInterval = null; // THIS IS THE SLIDER INTERVAL
    this.state = { carouselInt: true, idx: 0, carouselEnd: false };
    this.handleCarouselMove = this.handleCarouselMove.bind(this);
    this.handleIntervalCarouselMove =
      this.handleIntervalCarouselMove.bind(this);
  }

  componentDidMount() {
    this.handleIntervalCarouselMove();
  }

  componentWillUnmount() {
    clearInterval(this.sliderInterval);
  }

  handleCarouselMove(e) {
    this.setState({ carouselInt: false });
    const selectorArr = Array.from(
      document.querySelectorAll(".carousel-selector")
    );
    let idx = selectorArr.indexOf(e.target);
    selectorArr.forEach((each) => {
      each.classList.remove("active-selector");
    });
    e.target.classList.add("active-selector");
    document.querySelector(
      ".home-carousel-content"
    ).style.transform = `translateX(${idx * -20}%)`;
  }

  handleIntervalCarouselMove() {
    const selectorArr = Array.from(
      document.querySelectorAll(".carousel-selector")
    );
    this.sliderInterval = setInterval(() => {
      if (this.state.carouselInt == false) {
        clearInterval(this.sliderInterval);
        return;
      } else {
        this.setState({ idx: this.state.idx + 1 });
        this.setState({carouselEnd: false})

        if (this.state.idx >=3) {
   
            document.querySelector(
              ".home-carousel-content"
            ).style.transform = `translateX(${3 * -20}%)`;

            setTimeout(() => {
              this.setState({carouselEnd: true})
              this.setState({ idx: 0 });
              document.querySelector(
                ".home-carousel-content"
              ).style.transform = `translateX(${0}%)`;
          }, 500)
       
          setTimeout(() => {
            this.setState({carouselEnd: false})
          }, 750)
          selectorArr[2].classList.remove("active-selector");
          selectorArr[0].classList.add("active-selector");
        } else {

        
        document.querySelector(
          ".home-carousel-content"
        ).style.transform = `translateX(${this.state.idx * -20}%)`;
        selectorArr.forEach((each) => each.classList.remove("active-selector"));
        selectorArr[this.state.idx].classList.add("active-selector");
      }
      }
    }, 2500);
  }

  render() {
    return (
      <section className="home">
        <div className="home-container">
          <div className="home-carousel-container">
            <div className={this.state.carouselEnd ? 'home-carousel-content end-carousel-swtich' : 'home-carousel-content'}>
              <div className="hero-carousel-slide">
                {/* <div>
                  <h2>Lorem Ipsum</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div> */}
                <img src="/carousel-imgs/slide-a.png" alt="" />
              </div>



              <div className="hero-carousel-slide">
                {/* <div>
                  <h2>Lorem Ipsum</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div> */}
                <img src="/carousel-imgs/slide-c.jpeg" alt="" />
              </div>

              <div className="hero-carousel-slide">
                {/* <div>
                  <h2>Lorem Ipsum</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div> */}
                <img src="/carousel-imgs/slide-b.jpeg" alt="" />
              </div>

              <div className="hero-carousel-slide">
                {/* <div>
                  <h2>Lorem Ipsum</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div> */}
                <img src="/carousel-imgs/slide-a.png" alt="" />
              </div>
            </div>
            <div className="carousel-controls">
              <button
                onClick={(e) => this.handleCarouselMove(e)}
                className="carousel-selector active-selector"
              ></button>
              <button
                onClick={(e) => this.handleCarouselMove(e)}
                className="carousel-selector"
              ></button>
              <button
                onClick={(e) => this.handleCarouselMove(e)}
                className="carousel-selector"
              ></button>
            </div>
          </div>
        </div>

        <h1>Products</h1>

        <div className="products-home">
          <div>
            <Link to="/AdvantageArrest/5mLBottle">
              <div className="">
                <img
                  src="/imgs/products/SilverDiamineFluoride5ml.png"
                  alt="Advantage Arrest Silver Diamine Fluoride 38% - 5 mL Bottle Image"
                />
                <h3>
                  Advantage Arrest<sup>®</sup> Silver Diamine Fluoride 38% - 5
                  mL Bottle
                </h3>
              </div>
            </Link>
            <Link to="/AdvantageArrest/Ampule">
              <div className="">
                <img
                  className="unit"
                  src="/imgs/products/SilverDiamineFluorideCap.png"
                  alt="Advantage Arrest Silver Diamine Fluoride 38% - 50 Unit-Dose Ampules Image"
                />
                <h3>
                  Advantage Arrest<sup>®</sup> Silver Diamine Fluoride 38% - 50
                  Unit-Dose Ampules
                </h3>
              </div>
            </Link>
          </div>
          <div>
            <Link to="/FluoriMax/Bottle">
              <div className="">
                <img
                  src="/imgs/products/FlouriMax-BubbleGum-Bottle.png"
                  alt="FluoriMax™ Varnish - 1 Bottle - Bubble Gum"
                />

                <h3>
                  FluoriMax<sup>®</sup> NaF Varnish - 1 Bottle - Bubble Gum
                </h3>
              </div>
            </Link>
            <Link to="/FluoriMax/Ampule">
              <div className="">
                <img
                  className="unit"
                  src="/imgs/products/FluoriMax-Varnish-Flow-Through.jpg"
                  alt="FluoriMax™ Varnish - Flow-Through Unit-Dose Ampule - Bubble Gum "
                />

                <h3>
                  FluoriMax<sup>®</sup> NaF Varnish - Flow-Through Unit-Dose Ampule
                  - Bubble Gum
                </h3>
              </div>
            </Link>
          </div>
        </div>
      </section>
    );
  }
}
