

export default function ErrorPage() {
    return(
        <div className="error-page-container">
        <div className="error-page-svg">
            
        </div>
        <h2>Error 404</h2>
        <p>This link appears to be missing. We'll fix that soon enough.</p>
        </div>
    )
}