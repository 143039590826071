import {Link } from "react-router-dom";

const MailSent = () => {
    return(
        <div className="mail-sent-container">
            <img src="imgs/tooth.svg"/>
            <h1>Thank you for contacting us. We will respond as soon as we can!</h1>
            <Link to="/">Return Home</Link>
        </div>
    )
}

export default MailSent; 