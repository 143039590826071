import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';

export default class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {activeNav: false}

        this.handleActiveNav = this.handleActiveNav.bind(this)
    }

 handleActiveNav() {
    this.setState({activeNav: !this.state.activeNav})
}
    render() {
        return(
            <header className="header">
            <div className='header-logo-cont'><Link to="/">
                <img src="/imgs/headerLogo.svg" alt="Elevate Care Logo" className="header--logo"/>
                </Link></div>
            <button onClick={this.handleActiveNav} className={this.state.activeNav ? "hamburger active-nav" : "hamburger"}> <div></div></button>
                <nav className={this.state.activeNav ? "active-nav nav" : "nav"}>
                 <ul className="nav--links">

                    <li><Link onClick={this.handleActiveNav} to="/contact-us">Contact Us</Link></li>

                     <li>Resources <ul className="nav--links__resources-links">
                        <li><Link onClick={this.handleActiveNav} to="/about-us">About Us</Link></li>
                        <li><Link onClick={this.handleActiveNav} to="/where-we-are">Where We Are</Link></li>
                     </ul></li>
                     <li>Products
                     <ul className="nav--links__products-links">
                         <li><Link onClick={this.handleActiveNav} to="/FluoriMax">FluoriMax<sup>®</sup>2.5% NaF Varnish</Link></li>
                         <li><Link onClick={this.handleActiveNav} to="/AdvantageArrest">Advantage Arrest<sup>®</sup>Silver Diamine Fluoride 38%</Link></li>
                     </ul>
                     </li>
                     <li>Downloads
                     <ul className="nav--links__downloads-links">
                         {/* <li><Link onClick={this.handleActiveNav} to="/downloads-catalog">Virtual Catalog</Link></li> */}
                         <li><Link onClick={this.handleActiveNav} to="/downloads-FluoriMax">FluoriMax<sup>® </sup> 2.5% NaF Varnish</Link></li>
                         <li><Link onClick={this.handleActiveNav} to="/downloads-AdvantageArrest">Advantage Arrest<sup>® </sup>Silver Diamine Fluoride 38%</Link></li>
                     </ul>
                     </li>
                     <li>Education
                     <ul className="nav--links__education-links">
                         <li><Link onClick={this.handleActiveNav} to="/studies">Studies</Link></li>
                         <li><Link onClick={this.handleActiveNav} to="/education-library">Education Library</Link></li>
                     </ul>
                     </li>
                 </ul>
                 <div className="mobile-nav-links">
                 {this.state.activeNav && <SocialLinks/>}
                 </div>
                </nav>
                
            </header>
        )
    }
}