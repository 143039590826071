import React from "react";

export default class CELibrary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="ce-library">

        
        <div
          align="center"
          className="full-width-image"
          style={{ textAlign: "center" }}
        >
          <img
            alt="Elevate Oral Care Logo"
            border="0"
            src="/imgs/CEIcon.jpeg"
            style={{
              width: "100%",
              maxWidth: "300px",
              height: "auto",
              width: "300",
            }}
          />
          <h1>&nbsp;</h1>

          <div className="webinarcontainer">
            <h1 style={{ textAlign: "center" }}>
              Education Is What Unites Us&nbsp;
            </h1>

            <div style={{ textAlign: "center" }}></div>

            <div style={{ textAlign: "center" }}>&nbsp;</div>

            <div style={{ textAlign: "center" }}>
              <p
                className="MsoNormal"
                style={{ textAlign: "left", fontFamily: "Calibri, sans-serif" }}
              >
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  Ancient Greeks had two words for time:<i>&nbsp;Chronos</i>
                  &nbsp;– or sequential time, and<i>&nbsp;Kairos</i>&nbsp;– an
                  opportune moment for change.&nbsp;Oral health has entered a
                  new normal with a need for the reduction of aerosol-producing
                  treatments. Our products focus on non- and minimally invasive
                  solutions, perfect for a&nbsp;<i>Kairos</i>&nbsp;moment.&nbsp;
                </span>

                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  To achieve change, we invested in Continuing Education. This,
                  along with support of outreach programs, access to care
                  efforts, Patient-Centered Communication skills, and oral
                  health advocacy is what we call&nbsp;
                </span>

                <i style={{ fontFamily: "Times New Roman, serif" }}>
                  Elevating Care
                </i>
                <font size="2" style={{ fontFamily: "Times New Roman, serif" }}>
                  ™
                </font>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  .&nbsp;
                </span>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  Below is a collection of CE to achieve&nbsp;
                </span>
                <i style={{ fontFamily: "Times New Roman, serif" }}>Kairos</i>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  &nbsp;in your practice. The topics are broad and beyond our
                  products. They branch into patient-centered communications,
                  teledentistry, special needs, general, geriatric, and oral
                  hygiene areas of dentistry.&nbsp;
                </span>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  We hope these offerings launch you into a prosperous,
                  educated, and healthy future.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  textAlign: "center",
                  fontFamily: "Calibri, sans-serif",
                }}
              >
            <hr></hr>
              </p>
            </div>

            <br />
            
          </div>
        </div>

        {/* <div>
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="300px"
            src="https://www.youtube.com/embed/yFy3LUKwZGw"
            title="YouTube video player"
            width="300px"
          ></iframe>

          <div className="webinartitle" style={{textAlign: 'center'}}>
            <b>Dr. Amber Lovatos</b>&nbsp;Courageous Conversation: Our Role in
            Human Trafficking<b>&nbsp;</b>&nbsp;Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="https://www.elevateoralcare.com/site/images/cevideos/Courageous_Conversation_Our_Role_in_Human_Trafficking.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Lovatos' course.
          </div>
        </div> */}
  <div className="webinarcell">
<iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0" height="auto" src="https://www.youtube.com/embed/yFy3LUKwZGw" title="YouTube video player" width="100%">
</iframe>
<div class="webinartitle"><b>Dr. Amber Lovatos</b>&nbsp;Courageous Conversation: Our Role in Human Trafficking<b>&nbsp;</b>&nbsp;Self-Study
</div>
<div class="webinardeck">Please&nbsp;<a href="https://www.elevateoralcare.com/site/images/cevideos/Courageous_Conversation_Our_Role_in_Human_Trafficking.pdf" target="_blank">download this file for your test and instructions</a>&nbsp;to obtain 1 hr CE Credit for Dr. Lovatos' course.

</div>
</div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/xYkEsef02tg"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Tim Wright</b>&nbsp;Non-aerosol Approaches in the Management
            of Dental Caries<b>&nbsp;</b>&nbsp;Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="https://www.elevateoralcare.com/site/images/cevideos/Non-aerosol_Approaches_in_the_Management_of_Dental_Caries_02072022.pdf"
              target="_blank"
              download
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Wright's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/6dprymAJkj8"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr.'s Allen, Brown, Brian, &amp; Meeske</b>&nbsp;Why Don’t
            Patients Come Back? - The Crucial Role of Patient Experience in
            Clinical Outcomes and Practice Success<b>&nbsp;</b>&nbsp;Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a href="#" target="_blank">
              download COMING SOON
            </a>
            &nbsp;to obtain 1 hr CE Credit for the course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/E7NSSuF6OBQ"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Joyce Kahng</b>&nbsp;Social Media for the Modern Dentist
            <b>&nbsp;</b>&nbsp;Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="https://www.elevateoralcare.com/site/images/cevideos/Social_Media_for_the_Modern_Dentist_Test_02042022.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Kahng's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/1GWhAOtE8WA"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            &nbsp;
            <b>Dr. Paul Glassman, Dr. Bill Jackson and Bruce Silverman </b>
            Teledentistry: How Can It Impact My Dental Practice?&nbsp;Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="https://www.elevateoralcare.com/site/images/cevideos/Teledentistry_How can it impact my dental practice_Test_11102021.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Glassman, Jackson, and
            Silverman's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/c6cOklvySKc"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            &nbsp;<b>Dr. Doug Young and Dr. Hien Ngo&nbsp;</b>Prevention in
            Dentistry Using Glass Ionomer Restoratives and Sealants Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="https://www.elevateoralcare.com/site/images/cevideos/Glass_Ionomer_Test_11102021.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Young and Ngo's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/yY9_x-pybeo"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            &nbsp;<b>Dr. Norman Tinanoff </b>Fluoride's Role in Post Pandemic
            Dentistry&nbsp;Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="https://www.elevateoralcare.com/site/images/cevideos/Dr._Norman_Tinanoff_Test_11102021.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Tinanoff's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/vPrkD1w6388"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            &nbsp;<b>Dr. Jeremy Horst&nbsp;</b>Iodine Use in Caries Prevention
            and Management. Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="https://www.elevateoralcare.com/site/images/cevideos/Active_Dr_Horst_Iodine_08172021.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Horst's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/EwA5vhA5UGI"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Jeremy Horst&nbsp;</b>Prevention of Caries by Silver Diamine
            Fluoride 38%. Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="https://www.elevateoralcare.com/site/images/cevideos/Dr_Horst_D1355_02012021.pdf"
              target="_self"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Horst's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/nHGdZScNfb0"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Drs. Matt Allen, Paul Glassman, and Jessica Meeske </b>Oral
            Health Delivery: Back to the Past or On to the Future?. Continuing
            Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="http://www.elevateoralcare.com/site/images/cevideos/Oral%20Health%20Delivery%20Webinar_Active.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Oral Health Delivery course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/Fo3pPPp4B8E"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Matt Allen</b> The Healing Power of Words. Continuing
            Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a href="http://www.elevateoralcare.com/allence" target="_blank">
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Allen's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/-A5CD-Tx_F8"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Jeanette MacLean</b> The SMART Pediatric Dentist. Continuing
            Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="http://www.elevateoralcare.com/MacLeance0326"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1.5 hr CE Credit for Dr. MacLean's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/fPrSW_tbFOc"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Jeremy Horst</b> Minimally Invasive Caries Treatments.
            Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a href="http://www.elevateoralcare.com/horstce1" target="_blank">
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1.5 hr CE Credit for Dr. Horst's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/zQ4qbzghjAQ"
            width="100%"
          ></iframe>
          <b style={{ fontSize: "13px" }}>Dr. Jeremy Horst</b>
          <span style={{ fontSize: "13px" }}>
            {" "}
            Non-Restorative Treatments as First Line Therapy for Caries.&nbsp;
          </span>
          <span style={{ fontSize: "13px" }}>
            Continuing Education Self-Study
          </span>

          <div className="webinardeck">
            Please&nbsp;
            <a href="http://www.elevateoralcare.com/horstce2" target="_blank">
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Horst's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/VImBLoomE3E"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Paul Glassman</b> Teledentistry and Minimally Invasive
            Procedures in the Time of COVID-19. Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a href="http://www.elevateoralcare.com/glassmance" target="_blank">
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Glassman's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/Ft5peCgA9T4"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            RDH Panel Discussion Flattening the Misinformation Curve -{" "}
            <b>Dental Hygiene During COVID-19.&nbsp;</b>Continuing Education
            Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a href="http://www.elevateoralcare.com/RDHpanel" target="_blank">
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for RDH Panel Discussion course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/hk3w5xZidkk"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Steven Parrett</b> Proactive Interventions in a General
            Dental Setting. Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a href="http://www.elevateoralcare.com/parrettce" target="_blank">
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Parrett's Proactive
            Interventions in a General Dental Setting.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/uCu-7mXcprU"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Brooke Fukuoka</b> Treating and Preventing Oral Disease
            Through Locked Doors and Beyond. Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a href="http://www.elevateoralcare.com/cefukuoka" target="_blank">
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 2 hrs CE Credit for Dr. Fukuoka Treating and
            Preventing Oral Disease Through Locked Doors and Beyond.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/pz16d_jScoY"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Jacob Dent</b> Oral Health Instruction for Special Needs
            Patients in a General Dental Setting. Continuing Education
            Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a href="http://www.elevateoralcare.com/dentce" target="_blank">
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1.5 hr CE Credit for Dr. Dent's OHI for Special
            Needs in a General Dental Setting.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/2iY8M28ymJs"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Drs. Paul Glassman</b>, <b>Matt Allen</b>,{" "}
            <b>Jeanette MacLean</b> and <b>Jeremy Horst</b> Clinical Care After
            the Crisis. Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="http://www.elevateoralcare.com/covidpanelce"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1.5 hrs CE Credit for the COVID-19 Panel Discussion
            course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/rYZfc8qAJDY"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Drs. Peter Milgrom</b>, <b>Philip Weinstein</b>,{" "}
            <b>Daniel McNeil </b>and <b>Marilyn Rothen RDH MS</b>&nbsp;Reducing
            Fear of Dentistry and COVID-19. Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            <font size="1">Please&nbsp;</font>
            <a
              href="http://www.elevateoralcare.com/site/images/cevideos/FearsWebinarTest.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            <span style={{ fontSize: "x-small" }}>
              &nbsp;to obtain 1.5 hrs CE Credit for the Fear Panel course.&nbsp;
            </span>
          </div>

          <div className="webinardeck">
            <font size="1">To download resources from this course:&nbsp;</font>
            <a
              href="http://www.elevateoralcare.com/site/images/cevideos/FearsWebinarSlides.pdf"
              target="_blank"
            >
              click here.
            </a>
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/_GTE3JUsztc"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Matt Allen</b> Engaging Patients as Partners. Continuing
            Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="http://www.elevateoralcare.com/site/images/cevideos/DrAllenPCCTestForm-040220.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Allen's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/p2-lXdJZrUk"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Jeremy Horst</b> Medical Management of Caries. Continuing
            Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="http://www.elevateoralcare.com/site/images/cevideos/HorstNSMTestForm.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Horst's course.
          </div>
        </div>

        <div className="webinarcell">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
            height="auto"
            src="https://www.youtube.com/embed/YW-qb6gkDjM"
            width="100%"
          ></iframe>

          <div className="webinartitle">
            <b>Dr. Jacob Dent</b> Special Needs Dentistry for the General
            Dentist. Continuing Education Self-Study
          </div>

          <div className="webinardeck">
            Please&nbsp;
            <a
              href="http://www.elevateoralcare.com/site/images/cevideos/DrDentNSMTestForm-040220.pdf"
              target="_blank"
            >
              download this file for your test and instructions
            </a>
            &nbsp;to obtain 1 hr CE Credit for Dr. Dent's course.
          </div>
        </div>

        <div style={{ clear: "both" }}>
          <h1 style={{ textAlign: "center" }}>
            Other Continuing Education Courses
          </h1>

          <div style={{ textAlign: "center" }}>
            ________________________________________________________________________
          </div>

          <div style={{ textAlign: "center" }}>&nbsp;</div>

          <div style={{ textAlign: "center" }}>
            <b>
              <font face="Times New Roman, serif">
                Penn Dental Medicine has launched a series of free online CE
                programs that can be accessed via their website.&nbsp;
                <a
                  href="https://www.dental.upenn.edu/continuing-education/online-classNameroom-2/center-for-persons-with-disabilities-presentation-series-2/"
                  target="_blank"
                >
                  
                  Click here to see
                  
                </a>
                &nbsp;the live and pre-recorded programs they are offering.
              </font>
            </b>

            <br />
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontFamily: "Times New Roman, serif" }}>
                _______________________________________________________________________&nbsp;
              </span>
            </span>
          </div>
        </div>
      </section>
    );
  }
}
