import React from "react";
import {Link } from "react-router-dom";
import ProductAside from "./ProductAside";



export default class AdvantageArrestAmpule extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section >
          <ProductAside/>
        <div className="AA-ampules-cont">
        <h1>
          Advantage Arrest<sup>®</sup> Silver Diamine Fluoride 38% - 50 Unit-Dose Ampules
        </h1>
        <img
          src="/imgs/products/SilverDiamineFluorideCap.png"
          alt="Advantage Arrest Silver Diamine Fluoride 38% - 50 Unit-Dose Ampules Image"
        />
        <h3>
          Advantage Arrest<sup>®</sup> made in the USA, helping patients around the world.
        </h3>
        <br></br>
        <p>
        Silver Diamine Fluoride has been used extensively around the globe for decades. Advantage Arrest silver diamine fluoride 38% will change how you offer your patients the protection they deserve. 
        </p>
        <br></br>
        <p>
        Advantage Arrest<sup>®</sup>:
        </p>

        <br></br>
        <ul>
          <li>Provides immediate relief from dentinal hypersensitivity</li>
          <li> Kills pathogenic organisms</li>
          <li>
            Hardens softened dentin making it more acid and abrasion resistant
          </li>
          <li>Does not stain sound dentin or enamel</li>
          <li>
            Can provide important clinical feedback due to its potential to
            stain visible or hidden lesions
          </li>
        </ul>
        <br></br>
        <p>
          Silver diamine fluoride 38% is indicated for the treatment of dentinal
          hypersensitivity. Advantage Arrest<sup>®</sup> is made in the USA and an FDA
          regulated prescription medical device.
        </p>
        <br></br>
        <h3>
          Please <Link to="/contact-us">Contact Us</Link> For Inquiries
        </h3>
        </div>
      </section>
    );
  }
}
