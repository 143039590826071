import React from "react";

export default class TermsOfUse extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="terms-of-use">
        <article>
          <h5>Exclusion of Liability</h5>
          <br></br>
          <p>
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
          </p>
          <br></br>
          <p>
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law. Indemnification
          </p>
          <br></br>
          <p>
            Your use of any information or materials on this website is entirely
            at your own risk, for which we shall not be liable. It shall be your
            own responsibility to ensure that any products, services or
            information available through this website meet your specific
            requirements.
          </p>
          <br></br>
          <p>
            This website contains material which is owned by or licensed to us.
            This material includes, but is not limited to, the design, layout,
            look, appearance and graphics. Reproduction is prohibited other than
            in accordance with the copyright notice, which forms part of these
            terms and conditions.
          </p>
          <br></br>
          <p>
            All trade marks reproduced in this website which are not the
            property of, or licensed to, the operator are acknowledged on the
            website.
          </p>
          <br></br>
          <p>
            Unauthorized use of this website may give rise to a claim for
            damages and/or be a criminal offense.
          </p>
          <br></br>
          <p>
            From time to time this website may also include links to other
            websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the
            website(s). We have no responsibility for the content of the linked
            website(s).
          </p>
          <br></br>
          <h5>Copyright</h5>
          <br></br>
          <p>
            Except for material in the public domain under United States
            copyright law, all material contained on the Web site (including all
            software, HTML code, Java applets, Active X controls and other code)
            is protected by United States and foreign copyright laws. Except as
            otherwise expressly provided in these terms and conditions, you may
            not copy, distribute, transmit, display, perform, reproduce,
            publish, license, modify, rewrite, create derivative works from,
            transfer, or sell any material contained on the Web site without the
            prior consent of the copyright owner.
          </p>
          <br></br>
          <p>
            None of the material contained on Generic Website may be
            reverse-engineered, disassembled, decompiled, transcribed, stored in
            a retrieval system, translated into any language or computer
            language, retransmitted in any form or by any means (electronic,
            mechanical, photo reproduction, recordation or otherwise), resold or
            redistributed without the prior written consent of Generic Website.
            Violation of this provision may result in severe civil and criminal
            penalties.
          </p>
        </article>
      </section>
    );
  }
}
