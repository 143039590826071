import React from "react";

export default class FlouriMaxData extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <article className="fluoriMax-data">
        <h1>Compare the performance of FluoriMax to traditional varnishes.</h1>
        <img src="/imgs/fluoridevarnishuptakechart061719.jpg" alt="" />
        <h1>
          FluoriMax 2.5% sodium fluoride varnish delivers almost twice the
          Fluoride Uptake vs. 3M™ Vanish™ 5% sodium fluoride varnish.
        </h1>
        <img src="/imgs/F-Efficiency_102615.jpg" alt="" />
        <h1>
          With FluoriMax, 38% of the fluoride in the formula is absorbed by the
          tooth, compared to its closest competitor at 14%. Back
        </h1>
        <a href="javascript:history.back()">Back</a>
      </article>
    );
  }
}
