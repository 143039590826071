import React from "react";

export default class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="privacy">
        <article>
          <p>
            This privacy notice discloses the privacy practices for Elevate Oral
            Care and its associated websites. This privacy notice applies solely
            to information collected by this organization and web site and
            notifies you of the following:
          </p>
<br></br>
          <ul>
            <li>
              What personally identifiable information is collected from you
              through the web site, how it is used and with whom it may be
              shared.
            </li>
            <li>
              What choices are available to you regarding the use of your data.
            </li>
            <li>
              The security procedures in place to prevent the misuse of your
              information.
            </li>
            <li>How you can correct any inaccuracies in the information.</li>
          </ul>
          <br></br>
          <h5>Information Collection, Use, and Sharing</h5>
          <p>
            We are the sole owners of the information collected on this site. We
            only have access to/collect information that you voluntarily give us
            via email or other direct contact from you. We will not sell or rent
            this information to anyone except our affiliated companies.
          </p>
          <br></br>
          <p>
            We will use your information to respond to you, regarding the reason
            you contacted us. We will not share your information with any third
            party outside of our organization, other than as necessary to
            fulfill your request, e.g. to ship an order.
          </p>
          <br></br>
          <p>
            Unless you ask us not to, we may contact you via email in the future
            to tell you about specials, new products or services, or changes to
            this privacy policy. We may share aggregated demographic information
            with our partners and advertisers. This is not linked to any
            personal information that can identify any individual person.
          </p>
          <br></br>
          <h5>Your Access to and Control Over Information</h5>
          <p>
            You may opt out of any future contacts from us at any time. You can
            do the following at any time by contacting us via the email address
            or phone number given on our website, or logging into your account
            at elevateoralcare.com:
          </p>
          <br></br>
          <ul>
            <li>See what data we have about you, if any.</li>
            <li>Change/correct any data we have about you.</li>
            <li>Have us delete any data we have about you.</li>
            <li>Express any concern you have about our use of your data.</li>
          </ul>
          <br></br>
          <h5>Registration</h5>
          <p>
            In order to use the Elevate Oral Care website you may need to
            register. During registration a user is required to give certain
            information (such as name, address and email address). This
            information is used to contact you about the products/services on
            our site in which you have expressed interest and protected with the
            same security defined in this privacy notice.
          </p>
          <br></br>
          <h5>Orders</h5>
          <p>
            We request information from you when you purchase a product from us.
            You may need to provide contact information (like name and shipping
            address) and financial information (like credit card number,
            expiration date). This information is used for billing purposes and
            to fill your orders. If we have trouble processing an order, we'll
            use this information to contact you.
          </p>
          <br></br>
          <h5>Cookies</h5>
          <p>
            We use "cookies" on this site. A cookie is a piece of data stored on
            a site visitor's hard drive to help us improve your access to our
            site and identify repeat visitors to our site. For instance, when we
            use a cookie to identify you, you would not have to log in a
            password more than once, thereby saving time while on our site.
            Cookies can also enable us to track and target the interests of our
            users to enhance the experience on our site.
          </p>
          <br></br>
          <h5>Security</h5>
          <p>
            Elevate Oral Care takes precautions to protect your information.
            When you submit sensitive information via the website, your
            information is protected both online and offline.
          </p>
          <br></br>
          <p>
            Wherever we collect sensitive information (such as credit card data)
            the information is encrypted and transmitted to us using a secure
            system. You can verify this by looking for a closed lock icon at the
            bottom of your web browser, or looking for "https" at the beginning
            of the address of the web page.
          </p>
          <br></br>
          <p>
            While we use encryption to protect sensitive information transmitted
            online, we also protect your information offline. Only employees who
            need the information to perform a specific job (for example, billing
            or customer service) are granted access to personally identifiable
            information. The computers/servers in which we store personally
            identifiable information are kept in a secure environment, not even
            on our premises. We partner with third parties to provide specific
            data storage and management services. Data management and storage
            companies do not have permission to use your (or our) information in
            any way.
          </p>
          <br></br>
          <p>
            Occasionally, Elevate Oral Care may partner with other companies to
            provide a service or product. When the user signs up for these
            services or products, we will share names, or other contact
            information that is necessary for the third party to provide these
            services or products. These parties are not allowed to use
            personally identifiable information except for the purpose of
            providing these services or products.
          </p>
          <br></br>
          <h5>Links</h5>
          <p>
            The Elevate Oral Care web site contains links to other sites. Please
            be aware that we are not responsible for the content or privacy
            practices of any other sites. We encourage our users to be aware
            when they leave our site and to read the privacy statements of any
            other site that collects personally identifiable information.
          </p>
          <br></br>
          <h5>Surveys & Contests</h5>
          <p>
            From time-to-time Elevate Oral Care may collect information via
            surveys or contests. Participation in these surveys or contests is
            completely voluntary and you may choose whether or not to
            participate and therefore disclose this information. Information
            requested may include contact information (such as name and shipping
            address), and demographic information (such as zip code, age level).
            Contact information will be used to notify the winners and award
            prizes. Survey information will be used for purposes of monitoring
            or improving the use and satisfaction of this site and Elevate Oral
            Care as a whole.
          </p>
          <br></br>
          <h5>
            If you feel that we are not abiding by this privacy policy, you
            should contact us immediately via telephone at 866-701-2076 or here.
          </h5>
          <br></br>
          <h5>
            Elevate Oral Care limits Facility and Administration fees on
            research programs to 5%.
          </h5>
        </article>
      </section>
    );
  }
}
