import React from 'react'


export default class Studies extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {
        return(
            <section className="studies">
                <h1>Studies</h1>
                <div>
                    <p>Advantage Arrest Scientific Literature Review</p>
                    <a href="/downloads/International_Silver_Diamine_Fluoride_Research_Review_031822.pdf" download>DOWNLOAD</a>
                </div>
                <h2>More Coming Soon</h2>
            </section>
        )
    }
}